


import {Component, Vue, Watch} from 'vue-property-decorator';
import {Validations} from 'vuelidate-property-decorators';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import {email, url} from 'vuelidate/lib/validators';
import EventLanguageSelector from '@/_modules/events/components/event-language-selector/event-language-selector.vue';
import {startGoogleMaps} from '@/services/googleMaps';
import eventLocation from '@/views/components/eventLocation/eventLocation.vue';
import {EventAccessType, TCategory, TEvent, TEventSettings} from '@/_types/event.type';
import {TCountry} from '@/_types/country.type';
import {mapGetters, mapState} from 'vuex';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import {TCity} from '@/_types/city.type';
import ValidationHelper from '@/_helpers/validation.helper';
import {TFile} from '@/_types/file.type';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';
import DateTimeHelper from '@/_helpers/date-time.helper';
import timezone from '@/_modules/events/components/timezones.json';
import {TTimezoneInfo} from '@/_types/timezone-info.type';
import {TranslateResult} from 'vue-i18n';
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import _cloneDeep from 'lodash.clonedeep';
import ErrorInfo from '@/_modules/error-info/error-info.vue';
import {Getter} from 'vuex-class';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

type TFormData = {
  title?: string;
  descr?: string;
  image?: string;
  date_start: Date;
  date_end: Date;
  url?: string;
  buy_ticket_url?: string;
  buy_booth_url?: string;
  photos?: TFile[];
  address?: string;
  country_id?: number;
  city_id?: number;
  agendas?: string;
  org_name?: string;
  org_phone?: string;
  org_email?: string;
  org_logo?: string;
  access_type?: EventAccessType;
  event_type_id?: number;
  categories?: TCategory[];
  is_private?: boolean;
  country_name?: string;
  city_name?: string;
  location?: string;
  longitude?: string;
  latitude?: string;
  languages?: string[];
  time_region: string;
}

@Component({
  components: {
    EventLanguageSelector,
    eventLocation,
    FormFileUploader,
    IconSquareEdit,
    IconSquareDelete,
    IconPlus,
    VueTelInput,
    ErrorInfo
  },
  computed: {
    ...mapState('eventStore', ['categoryList', 'typeList']),
    ...mapGetters({
      event: '_eventStore/event',
      eventContactTags: '_eventStore/eventContactTags',
      isEventContactTagsLoading: '_eventStore/isEventContactTagsLoading',
      eventSettings: '_eventStore/eventSettings',
    }),
  },
})

export default class EditEvent extends Vue {
  @Getter('_eventStore/eventError') eventError: ApiErrorResponseData;

  @Validations()
  public readonly validations: TVuelidateRuleSet<TFormData> = {
    formData: {
      org_email: {
        email
      },
      org_phone: {
        isValidPhoneNumber(org_phone: string): boolean {
          return ValidationHelper.isValidPhoneNumber(org_phone);
        },
        isValidPhoneNumberLength(org_phone: string): boolean {
          return ValidationHelper.isValidPhoneNumberLength(org_phone);
        }
      },
      url: {
        url
      },
      buy_ticket_url: {
        url
      },
      buy_booth_url: {
        url
      },
    },
  }

  public readonly event: TEvent;
  public languages: string[] = [];
  public image: TFile = null;
  public orgLogo: TFile = null;
  public photos: TFile[] = [];
  public createdHintText: TranslateResult = '';
  public accessSwitcher: boolean = false;
  public phoneNum: string = '';
  public imageError: string = '';
  public isImageLoading: boolean = false;
  public logoError: string = '';
  public isLogoLoading: boolean = false;
  public photosError: string = '';
  public isPhotosLoading: boolean = false;
  public readonly eventSettings: TEventSettings;
  public isQuickRegisterPopoverVisible: boolean = false;
  public imageErrorData: ApiErrorResponseData = null;
  public logoErrorData: ApiErrorResponseData = null;
  public photosErrorData: ApiErrorResponseData = null;

  public get accessTypeAccessor(): EventAccessType {
    if (this.accessSwitcher) {
      return EventAccessType.FREE;
    }
    return EventAccessType.REGISTER;
  }

  public get country(): TCountry | void {
    if (this.event && this.event.country) {
      return this.event.country;
    }
    return null;
  }

  public get city(): TCity | void {
    if (this.event && this.event.city) {
      return this.event.city;
    }
    return null;
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId) : null;
  }

  private patchEventSettingsQuickRegister(): void {
    const payload: any = _cloneDeep(this.eventSettings);
    if (!payload.layout) {
      payload.layout = {};
    }
    payload.layout.use_quick_register = this.isQuickRegisterSelected;

    this.$store.dispatch('_eventStore/patchEventSettings', {
      eventId: this.eventId,
      layout: payload.layout,
      show_meetings: this.getShowMeetingsSetting(),
    });
  }

  private getShowMeetingsSetting(): boolean {

    if (
      !this.eventSettings
      || !Object.prototype.hasOwnProperty.call(this.eventSettings, 'show_meetings')
      || typeof this.eventSettings.show_meetings !== 'boolean'
    ) {
      return null;
    }

    return this.eventSettings.show_meetings;
  }

  @Watch('accessSwitcher')
  private onAccessSwitcherChange(): void {
    this.formData.access_type = this.accessTypeAccessor;
    this.processQuickRegisterOption();
  }

  @Watch('eventSettings', {immediate: true, deep: true})
  private onEventSettingsChange(): void {
    if (this.eventSettings && this.eventSettings.layout && this.eventSettings.layout.use_quick_register) {
      this.isQuickRegisterSelected = true;
    }
  }

  private processQuickRegisterOption(): void {
    if (this.formData.access_type !== EventAccessType.FREE) {
      this.setQuickRegisterSelected(false);
    }
  }

  private setQuickRegisterSelected(newValue: boolean): void {
    this.isQuickRegisterSelected = newValue;
    this.patchEventSettingsQuickRegister();
  }

  @Watch('image', {deep: true})
  private onImageChange(): void {
    this.formData.image = this.image.url;
  }

  @Watch('formData.org_phone', {immediate: true})
  private onPhoneChange(): void {
    // const phoneInput = this.$refs.phoneInput;
    // phoneInput.$children[0].value = this.formData.org_phone;
    const phone = this.formData.org_phone;
    this.formData.org_phone = '';
    this.formData.org_phone = phone;
  }

  @Watch('orgLogo', {deep: true})
  private onOrgLogoChange(): void {
    this.formData.org_logo = this.orgLogo.url;
  }

  @Watch('photos', { deep: true })
  private onPhotosChange(): void {
    this.formData.photos = this.photos;
  }

  @Watch('event', {immediate: true, deep: true})
  private onEventInfoChange(): void {
    if (!this.event) { return; }

    this.formData.title = this.event.title;
    this.formData.descr = this.event.descr;
    this.formData.image = this.event.image;
    this.formData.date_start = new Date(this.event.date_start);
    this.formData.date_end = new Date(this.event.date_end);
    this.formData.url = this.event.url;
    this.formData.buy_ticket_url = this.event.buy_ticket_url;
    this.formData.buy_booth_url = this.event.buy_booth_url;
    this.formData.photos = (this.event.photos || []).map(item => {
      return Object.assign({}, item, {
        url: item
      });
    });
    this.formData.address = this.event.address;
    this.formData.country_name = this.event.map.country_name;
    this.formData.city_name = this.event.map.city_name;
    this.formData.location = this.event.map.location;
    this.formData.longitude = this.event.map.longitude;
    this.formData.latitude = this.event.map.latitude;

    this.formData.agendas = this.event.agendas;
    this.formData.org_name = this.event.org_name;
    this.formData.org_phone = this.event.org_phone;
    this.formData.org_email = this.event.org_email;
    this.formData.org_logo = this.event.org_logo;
    this.accessSwitcher = this.event.access_type === 'free';
    this.formData.access_type = this.accessTypeAccessor;
    this.formData.event_type_id = this.event.event_type.id;
    this.formData.is_private = this.event.is_private;
    this.formData.categories = (this.event.categories || []).map(item => {
      return item;
    });

    this.formData.time_region = this.event.time_region;

    this.photos = this.formData.photos;

    this.formData.time_region = this.event.time_region;

    for (let i = 0; i < timezone.length; i++) {
      if (timezone[i].utc.indexOf(this.formData.time_region) > -1) {
        this.timezoneLocation = timezone[i];
      }
    }
    this.phoneNum = this.formData.org_phone;
  }

  @Watch('timezoneLocation', {immediate: true})
  private onTimezoneLocationChanged(): void {
    if (this.timezoneLocation && !this.timezoneLocation.utc) { return; }
    this.formData.time_region = this.timezoneLocation.utc[0];
  }

  public created(): void {
    this.$store.dispatch('eventStore/eventType');
  }

  public formData: TFormData = {
    title: '',
    descr: '',
    image: null,
    date_start: null,
    date_end: null,
    url: '',
    buy_ticket_url: '',
    buy_booth_url: '',
    photos: [],
    address: '',
    country_id: null,
    city_id: null,
    agendas: '',
    org_name: '',
    org_phone: '',
    org_email: '',
    org_logo: null,
    access_type: null,
    event_type_id: null,
    categories: [],
    is_private: true,
    country_name: '',
    city_name: '',
    location: '',
    longitude: '',
    latitude: '',
    time_region: ''
  };

  public endDateMinimum: Date = new Date()
  public isShowStartDate: boolean = false
  public isShowEndDate: boolean = false
  public isShowTimezoneLocation: boolean = false
  public isShowEventType: boolean = false
  public isMultipleLanguages: boolean = false
  public isSendSuccess: boolean = false
  public updatedHintText: TranslateResult = ''
  public timezone: TTimezoneInfo[] = timezone;
  public timezoneLocation: TTimezoneInfo = {} as TTimezoneInfo;
  public isQuickRegisterSelected: boolean = false;

  private addressToCoords(): void {
    if (this.formData.country_name && this.formData.city_name) {
      this.requestAddressCoords({
        country: this.formData.country_name,
        city: this.formData.city_name
      });
    }
  }

  private setLocationData(value: any): void { // TODO: add type
    this.formData.country_id = value.country_id;
    this.formData.city_id = value.city_id;
  }

  public setCoords(lat: any, lon: any): void { // TODO: find what type it is
    this.formData.latitude = lat;
    this.formData.longitude = lon;
  }

  private requestAddressCoords(value: any): void { // TODO: add type
    /* attempt to get Lat Long coords from Google and save them into mapData.map */
    const address = this.formData.address;
    const country = value.country;
    const city = value.city;
    this.formData.city_name = city;
    this.formData.country_name = country;

    if (process.env.VUE_APP_MAP_PROVIDER !== 'google') {
      return;
    }

    startGoogleMaps().then(() => {
      const request = {
        query: [address, city, country].filter((x) => x).join(', '),
        fields: ['name', 'geometry']
      };

      // PlacesService requires a DIV element or a map element
      if ((window as any).google) {
        const service = new (window as any).google.maps.places.PlacesService(document.createElement('div'));

        // send request, use response in callback
        service.findPlaceFromQuery(request, (results: any, requestStatus: any) => {
          if (requestStatus === (window as any).google.maps.places.PlacesServiceStatus.OK) {
            this.setCoords(results[0].geometry.location.lat(), results[0].geometry.location.lng());
          }
        });
      }
    });
  }

  private deleteHeadImage(): void {
    this.formData.image = '';
  }

  private deleteLogoImage(): void {
    this.formData.org_logo = '';
  }

  private deletePhoto(index: number): void {
    if (!this.formData.photos[index]) {
      return;
    }
    const removedUrl = this.formData.photos[index].url;
    this.formData.photos.splice(index, 1);
    this.photos.forEach((item, index) => {
      if (item.url === removedUrl) {
        this.photos.splice(index, 1);
      }
    });
    // this.photos.splice(this.photos.findIndex(item => item.url === removedUrl), 1);
  }

  private async patchEvent(): Promise<void> {
    this.$v.formData.$touch();

    if (!this.formData || this.$v.formData.$pending || this.$v.formData.$invalid) {
      return;
    }

    const eventId = this.$route.params.eventId;

    const payload = {
      eventId: eventId,
      formData: Object.assign({}, this.formData, {
        photos: this.formData.photos.map(file => file.url),
        date_start: DateTimeHelper.dateToApiDate(this.formData.date_start),
        date_end: DateTimeHelper.dateToApiDate(this.formData.date_end),
      }),
    };

    const result = await this.$store.dispatch('_eventStore/editEvent', payload);
    if (result) {
      this.$v.$reset();
      this.isSendSuccess = true;
      await this.$store.dispatch('_eventStore/refresh');
      this.updatedHintText = this.$t('eventPage.edit.updatedSuccess');
    } else {
      this.isSendSuccess = false;
      this.updatedHintText = this.$t('eventPage.edit.updatedError');
    }

    setTimeout(() => {
      this.updatedHintText = '';
    }, 3000);
  }

  public phoneNumberHandler(phone: any, obj: any): void {
    this.formData.org_phone = obj.number;
  }

  private setImageError(errorMessage: string, errorData: ApiErrorResponseData): void {

    this.imageError = errorMessage || '';
    this.imageErrorData = errorData || null;
    setTimeout(() => {
      this.imageError = '';
      this.isImageLoading = false;
    }, 3000);
  }
  private setIsImageLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isImageLoading = true;
      return;
    } else if (value.url) {
      this.isImageLoading = false;
      // this.image = value;
    }
    this.imageErrorData = null;
    this.isImageLoading = false;
  }
  private setLogoError(errorMessage: string, errorData: ApiErrorResponseData): void {
    this.logoError = errorMessage || '';
    this.logoErrorData = errorData || null;
    setTimeout(() => {
      this.logoError = '';
      this.isLogoLoading = false;
    }, 3000);
  }
  private setIsLogoLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isLogoLoading = true;
      return;
    } else if (value.url) {
      this.isLogoLoading = false;
      // this.orgLogo = value;
    }
    this.logoErrorData = null;
    this.isLogoLoading = false;
  }
  private setPhotosError(errorMessage: string, errorData: ApiErrorResponseData): void {
    this.photosError = errorMessage || '';
    this.photosErrorData = errorData || null;
    setTimeout(() => {
      this.photosError = '';
      this.isPhotosLoading = false;
    }, 3000);
  }
  private setIsPhotosLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isPhotosLoading = true;
      return;
    } else if (value.url) {
      this.isPhotosLoading = false;
      // this.imageUrl = value.url;
    }
    this.isPhotosLoading = false;
  }

  private preview(): void {
    const routeData = this.$router.resolve({name: 'event-info', params: { eventId: this.$route.params.eventId }});
    window.open(routeData.href, '_blank');
  }

  private showQuickRegisterPopover(): void {
    this.isQuickRegisterPopoverVisible = true;
  }

  private hideQuickRegisterPopover(): void {
    this.isQuickRegisterPopoverVisible = false;
  }
}
